import React from 'react';
import loadable from '@loadable/component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// import ReactPlayer from 'react-player/lazy';

import { makeStyles } from '@material-ui/core/styles';
import { Dialog, Backdrop, Grid } from '@material-ui/core';

const Video = loadable(() => import('./Video'));

const useStyles = makeStyles((theme) => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '4rem',
		background: 'transparent',
	},
	paper: {
		overflow: 'visible',
	},
	closeIcon: {
		color: theme.white,
		backgroundColor: 'transparent',
		fontSize: 45,
		marginBottom: '0',
		position: 'absolute',
		top: '-40px',
		right: '-10px',
		zIndex: '99',
		transition: 'all .3s ease-out',
		opacity: '0.8',
		'&:hover': {
			cursor: 'pointer',
			opacity: '1',
			transform: 'rotate(180deg) ',
		},
	},
	playerWrapper: {
		position: 'relative',
		paddingTop: '57.25%',
		minHeight: '100%',
		minWidth: '100%',
		// border: '1px solid #d8dee0',
		// marginTop: '.5rem',
	},
	reactPlayer: {
		position: 'absolute',
		top: 0,
		left: 0,
	},
}));

export const HeroModal = ({
	open,
	homeHeroVideo,
	setHeroModalOpen,
	playing,
}) => {
	const classes = useStyles();

	return (
		open && (
			<div>
				<Dialog
					aria-labelledby='spring-modal-title'
					aria-describedby='spring-modal-description'
					className={classes.modal}
					classes={{
						paper: classes.paper,
					}}
					maxWidth='md'
					open={open}
					onClose={(e) => setHeroModalOpen(false)}
					BackdropComponent={Backdrop}>
					<FontAwesomeIcon
						onClick={(e) => setHeroModalOpen(false)}
						className={classes.closeIcon}
						icon={['fas', 'xmark']}
						size='1x'
					/>
					<Grid
						container
						direction='row'
						justifyContent='center'
						alignItems='center'
						item
						style={{ height: '90vh', width: '90vw' }}
						xs>
						<div className={classes.playerWrapper}>
							{/* <ReactPlayer
								url={homeHeroVideo}
								className={classes.reactPlayer}
								height='100%'
								width='100%'
								controls={true}
							/> */}
							<Video
								url={homeHeroVideo}
								className={classes.reactPlayer}
								height='100%'
								width='100%'
								controls={true}
								playing={playing}
							/>
						</div>
					</Grid>
				</Dialog>
			</div>
		)
	);
};
