import React, { useEffect, useState, useRef } from 'react';
// import { useElementSize } from 'use-element-size';

export const WaveDownSVG = ({ fill, oneSide, heroRef }) => {
	const [height, setHeight] = useState(0);
	const ref = useRef(null);
	const browser = typeof window !== 'undefined';

	const onResize = () => {
		setHeight(ref.current?.clientHeight);
	};

	useEffect(() => {
		browser && window.addEventListener('resize', onResize);

		setHeight(ref.current?.clientHeight);
	});

	return (
		<svg
			style={{
				marginBottom: `-${height}px`,
			}}
			ref={ref}
			role='presentation'
			viewBox='0 0 2160 263'
			fill={fill}
			xmlns='http://www.w3.org/2000/svg'>
			<path
				id='Wave'
				fill-rule='evenodd'
				clip-rule='evenodd'
				d='M2160 0H0V262.5C360 182.5 720 142.5 1080 142.5C1440 142.5 1800 182.5 2160 262.5V0Z'
				fill={fill}
			/>
		</svg>
	);
};
