import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';

import {
	TextField,
	InputAdornment,
	makeStyles,
	SvgIcon,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SearchTwoToneIcon from '@material-ui/icons/SearchTwoTone';

const useStyles = makeStyles((theme) => ({
	inputLabel: {
		padding: '0px',
		'&.shrink': {
			backgroundColor: 'white',
			padding: '2px',
			borderRadius: '5px',
		},
	},
	searchIconBackground: {
		height: '56px',
		width: '56px',
		position: 'relative',
		left: '47px',
		background: '#002D5C',
	},
	searchIcon: {
		color: '#fff',
		height: '56px',
		width: '56px',
		padding: '6px',
		background: '#002D5C',
		borderRadius: '0 8px 8px 0',
	},
	searchField: {
		background: 'transparent',
		paddingRight: '0',
		padding: '0',
		bordrerRadius: '8px',
		border: 'none',
		maxWidth: '100%',
		width: '795px',
		[theme.breakpoints.down('md')]: {
			width: '100%',
		},
	},
	root: {
		borderRadius: '8px',
		background: '#fff',
	},
	fieldBorder: {
		border: '2px solid #002D5C',
	},
}));

export const IndustrySearch = ({
	handleSubmit,
	industries,
	searchRef,
	handleChange,
}) => {
	const [sortedIndustries, setSortedIndustries] = useState([]);

	//sort drop down results so top 14 show up first sorted by importance, then all others alphabetically
	useEffect(() => {
		const other = industries.edges
			.filter((industry) => !industry.node.mosaicType[0])
			.sort((a, b) => a.node.title.localeCompare(b.node.title));
		const first = industries.edges.filter(
			(industry) => industry.node.mosaicType[0]
		);
		const primary = first
			.filter((industry) => industry.node.mosaicType[0].title === 'large')
			.sort((a, b) => b.node.mosaicPrimary - a.node.mosaicPrimary);
		const secondary = first
			.filter((industry) => industry.node.mosaicType[0].title === 'medium')
			.sort((a, b) => b.node.mosaicPrimary - a.node.mosaicPrimary);
		const tertiary = first
			.filter((industry) => industry.node.mosaicType[0].title === 'small')
			.sort((a, b) => b.node.mosaicPrimary - a.node.mosaicPrimary);

		setSortedIndustries([...primary, ...secondary, ...tertiary, ...other]);
	}, []);

	const classes = useStyles();
	return (
		<form onSubmit={handleSubmit} style={{ width: '100%' }}>
			<Autocomplete
				className={classes.searchFieldParent}
				id='industries filter search'
				freeSolo
				style={{ borderRight: 'none', background: 'transparent' }}
				ref={searchRef}
				onInputChange={(e, value) => handleChange(e, value)}
				options={sortedIndustries.map((options) => {
					return options.node.title;
				})}
				renderInput={(params) => (
					<TextField
						className={classes.searchField}
						{...params}
						label='Search for your industry'
						margin='normal'
						variant='outlined'
						color='primary'
						InputLabelProps={{
							classes: {
								root: classes.inputLabel,
								shrink: 'shrink',
							},
						}}
						outlinedInputProps={{
							classes: { root: classes.fieldBorder },
						}}
						InputProps={{
							classes: {
								root: classes.inputLabel,
								root: classes.root,
							},
							...params.InputProps,
							type: 'search',
							endAdornment: (
								<InputAdornment className={classes.searchIconBackground}>
									{/* <FontAwesomeIcon
										icon={['fad', 'search']}
										style={{
											color: '#002D5C',
											height: '60px',
											width: '60px',
										}}
									/> */}
									{/* <SvgIcon className={classes.searchIcon}>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											height='.7em'
											viewBox='0 0 512 512'
											fill='#FFF'>
											<path d='M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z' />
										</svg>
									</SvgIcon> */}
									<SearchTwoToneIcon className={classes.searchIcon} />
								</InputAdornment>
							),
						}}
					/>
				)}
			/>
		</form>
	);
};
