import React, { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// import ReactPlayer from 'react-player/lazy';

import { makeStyles } from '@material-ui/core/styles';
import { Dialog, Backdrop, Grid } from '@material-ui/core';

import PromotionModalContext from '../../context/PromotionModalContext';
import { GatsbyImage } from 'gatsby-plugin-image';

const useStyles = makeStyles((theme) => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '4rem',
		minHeight: '98vh',
		maxHeight: '98vh',
		overflow: 'hidden',
		'& .MuiDialog-paperScrollPaper': {
			maxHeight: 'none',
		},
	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		border: '2px solid #000',
		boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 3),
		overflow: 'hidden',
	},
	img: {
		[theme.breakpoints.down('md')]: {
			maxHeight: '75%',
		},
		[theme.breakpoints.down('xs')]: {
			maxHeight: '100%',
		},
	},
	closeIcon: {
		color: theme.white,
		fontSize: 45,
		margin: '1rem 0 0 0',
		position: 'relative',
		bottom: '10px',
		transition: 'all .3s ease-out',
		opacity: '0.8',
		'&:hover': {
			cursor: 'pointer',
			opacity: '1',
			transform: 'rotate(180deg) ',
		},
		// [theme.breakpoints.down('md')]: {
		// 	margin: '1rem 1rem 0 1rem',
		// },
	},
}));

export const PromotionModal = ({ promotionModal }) => {
	const classes = useStyles();
	const { open, setOpen } = useContext(PromotionModalContext);

	const { backgroundImage, link } = promotionModal;

	return (
		open && (
			<div>
				<Dialog
					aria-labelledby='spring-modal-title'
					aria-describedby='spring-modal-description'
					className={classes.modal}
					maxWidth='sm'
					open={open}
					onClose={(e) => setOpen(false)}
					BackdropComponent={Backdrop}
					BackdropProps={{
						style: { backgroundColor: '#000', opacity: '0.80' },
					}}
					PaperProps={{
						style: {
							backgroundColor: 'transparent',
							boxShadow: 'none',
							margin: '0',
							// maxWidth: '100%',
							// overflow: 'visible',
							padding: '1rem',
						},
					}}>
					<Grid container item direction='row' justify='flex-end'>
						<FontAwesomeIcon
							icon={['fas', 'xmark']}
							size='1x'
							className={classes.closeIcon}
							onClick={(e) => setOpen(false)}
						/>
					</Grid>
					<a
						href={link}
						target='_blank'
						rel='noopener noreferrer'
						aria-label="Opens in a new tab"
						onClick={() => setOpen(false)}>
						<GatsbyImage
							image={backgroundImage.asset?.gatsbyImageData}
							imgStyle={{ objectFit: 'contain' }}
							className={classes.img}
						/>
					</a>
				</Dialog>
			</div>
		)
	);
};
