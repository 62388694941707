import React from 'react';
import PortableText from 'react-portable-text';

import { Grid, Container, Typography, makeStyles } from '@material-ui/core';
import { IntroCard } from './IntroCard';

const useStyles = makeStyles((theme) => ({
	intro: {
		// marginTop: '-1rem',
		position: 'relative',
		[theme.breakpoints.down('md')]: {
			marginTop: 0,
		},
		[theme.breakpoints.down('sm')]: {
			// marginTop: '-3rem',
		},
		[theme.breakpoints.down('xs')]: {
			marginTop: 0,
		},
	},
}));

export const Intro = ({ header, cards }) => {
	const classes = useStyles();
	return (
		<Container>
			<Grid container direction='row' justifyContent='center'>
				<Grid item xs={12} md={8}>
					<PortableText
						content={header}
						className={classes.intro}
						serializers={{
							h2: ({ children }) => (
								<Typography
									variant='h3'
									color='primary'
									style={{
										fontWeight: 700,
										paddingBottom: '4rem',
										textAlign: 'center',
									}}>
									{children}
								</Typography>
							),
						}}
					/>
				</Grid>
			</Grid>
			<Grid
				container
				direction='row'
				justifyContent='center'
				alignItems='center'
				spacing={4}>
				{cards.map((card, index) => (
					<IntroCard key={index} card={card} />
				))}
			</Grid>
		</Container>
	);
};
