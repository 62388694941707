import React, { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
	Grid,
	Container,
	Typography,
	Button,
	makeStyles,
} from '@material-ui/core';

import { ProductLogos } from '../ProductLogos';
import FormModalContext from '../../../context/FormModalContext';

const useStyles = makeStyles((theme) => ({
	root: {
		color: theme.workwaveBlue,
		paddingTop: '4rem',
	},
	button: {
		padding: '.8rem 1.8rem',
		color: 'white',
		'& .MuiButton-label': {
			fontSize: '1.6rem !important',
			fontWeight: 700,
		},
	},
	text: {
		fontWeight: 700,
		textAlign: 'left',
		flexWrap: 'nowrap',
		color: theme.workwaveBlue,
		[theme.breakpoints.down('sm')]: {
			textAlign: 'center',
		},
	},
	item: {
		'&:nth-child(3n)': {
			paddingLeft: '4rem',
		},
		[theme.breakpoints.down('md')]: {
			'&:nth-child(3n)': {
				paddingLeft: '2rem',
			},
		},
		[theme.breakpoints.down('sm')]: {
			'&:nth-child(3n)': {
				paddingLeft: '0rem',
			},
		},
	},
	container: {
		paddingLeft: '2rem',
		[theme.breakpoints.down('sm')]: {
			paddingLeft: '0rem',
		},
	},
}));

export const Offerings = ({
	header,
	offerings,
	learnHeader,
	ctaText,
	productLogosHeader,
	productLogos,
}) => {
	const classes = useStyles();

	const { setFormModalOpen } = useContext(FormModalContext);

	return (
		<>
			<Container className={classes.root}>
				<Grid container direction='row' justifyContent='center'>
					<Typography
						variant='h3'
						style={{
							fontWeight: 700,
							textAlign: 'center',
						}}>
						{productLogosHeader}
					</Typography>
				</Grid>
			</Container>
			<ProductLogos logos={productLogos} />
			<Container className={classes.root}>
				<Grid container direction='row' justifyContent='center'>
					<Typography
						variant='h3'
						style={{
							fontWeight: 700,
							textAlign: 'center',
							paddingBottom: '2rem',
						}}>
						{header}
					</Typography>
				</Grid>
			</Container>
			<Container>
				<Grid
					container
					spacing={3}
					direction='row'
					justifyContent='space-between'
					alignItems='center'
					className={classes.container}>
					{offerings.map((offering, index) => (
						<Grid
							item
							sm={6}
							md={4}
							xs={12}
							className={classes.item}
							key={index}>
							<Typography variant='h6' className={classes.text}>
								<FontAwesomeIcon
									icon={['fas', 'check']}
									style={{ marginRight: '.5rem', fontWeight: 700 }}
								/>
								{offering}
							</Typography>
						</Grid>
					))}
				</Grid>
				<Grid
					style={{ marginTop: '4rem' }}
					container
					direction='column'
					justifyContent='center'
					alignItems='center'>
					<Grid item lg={8}>
						<Typography
							variant='h4'
							color='primary'
							style={{ textAlign: 'center', fontWeight: 700 }}>
							{learnHeader}
						</Typography>
					</Grid>
					<Grid item style={{ margin: '1rem 0' }}>
						<Button
							variant='contained'
							size='large'
							color='secondary'
							onClick={() => setFormModalOpen(true)}
							style={{ fontSize: '1.6rem !important' }}
							className={classes.button}>
							{ctaText}
						</Button>
					</Grid>
				</Grid>
			</Container>
		</>
	);
};
